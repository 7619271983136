$(function($){
  $(document).on('scroll', function() {
    if ($(document).scrollTop() > 100) {
      $(".new-header").addClass("header-fixed");
      $(".header-v1").addClass("header-fixed");
    }
    if ($(document).scrollTop() < 1) {
      $(".new-header").removeClass("header-fixed");
      $(".header-v1").removeClass("header-fixed");
    }
  });

  $('.navbar-toggler').on('click', function (e) {
    if ($(this).hasClass('collapsed')) {
      $('body').addClass('mobile_menu_open');
    } else {
      $('body').removeClass('mobile_menu_open');
    }
  });
});
